<template>
  <AppContent class="popover-switch-organization" data-testid="popover-wrapper">
    <UIContent>
      <UIList>
        <!-- all filter -->
        <UIListItemClickable v-if="userStore.isPermitted(['platform.organizations.readAll'])" :label="$t('common.all')" @click="select('all')" />

        <!-- organizations -->
        <UIListItemClickable v-for="organization in organizations" :key="organization.id" :label="organization.name" data-testid="organization-card" :disabled="organizations.id === selected" @click="select(organization.id)" />
      </UIList>
    </UIContent>
  </AppContent>
</template>

<script>
// eslint-disable-next-line import/extensions
import useUserStore from '@/stores/UserStore';

import AppContent from '@/components/layout/AppContent.vue';
import UIContent from '@/components/ui/UIContent.vue';
import UIList from '@/components/ui/UIList.vue';
import UIListItemClickable from '@/components/ui/UIListItemClickable.vue';

export default {
  name: 'PopoverSwitchOrganization',
  components: {
    AppContent,
    UIContent,
    UIList,
    UIListItemClickable,
  },
  props: {
    // Function which closes the popup and hands over data
    resolve: {
      type: Function,
      default: () => {},
    },
    // Array of organization objects
    organizations: {
      type: Array,
      default: () => [],
    },
    // The id of the selected organization
    selected: {
      type: String,
      default: null,
    },
  },
  setup() {
    const userStore = useUserStore();

    return {
      userStore,
    };
  },
  computed: {
    user() {
      return this.userStore.user;
    },
  },
  data() {
    return {
    };
  },
  watch: {
  },
  created() {

  },
  methods: {
    async select(organizationId) {
      this.resolve({
        type: 'selected',
        organizationId,
      });
    },
  },
};
</script>

<style scoped>
.popover-switch-organization {
  --default-content-padding: 0.2em;
}

.popover-switch-organization .ui-list-item {
  padding-top: 0.4em;
  padding-bottom: 0.4em;
  font-size: var(--font-size-small);
  line-height: var(--line-height-small);
  margin-bottom: 0.2em;
}

.popover-switch-organization .ui-list {
  margin-bottom: -0.2em;
}
</style>
