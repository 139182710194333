<template>
  <div class="ui-list" :class="{ 'space-bottom': spaceBottom }">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'UIList',
  components: {
  },
  props: {
    spaceBottom: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
    };
  },
  computed: {
  },
  methods: {
  },
};
</script>

<style scoped>
.ui-list:deep(.ui-list-item) {
  margin-bottom: 0.5em;
}

.ui-list {
  margin-bottom: -0.5em;
}

.ui-list.space-bottom {
  margin-bottom: 1.5em;
}
</style>
