<template>
  <div class="ui-loader" :class="{ 'small': small }">
    <div class="lds-ellipsis"><div :style="`background: ${color}`"></div><div :style="`background: ${color}`"></div><div :style="`background: ${color}`"></div><div :style="`background: ${color}`"></div></div>
  </div>
</template>

<script>
export default {
  name: 'UILoader',
  components: {
  },
  props: {
    small: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: 'var(--color-primary)',
    },
  },
  data() {
    return {
    };
  },
  computed: {
  },
  methods: {
  },
};
</script>

<style scoped>
.ui-loader {
  text-align: center;
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: var(--color-primary);
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

/* SMALL STYLE */
.ui-loader.small .lds-ellipsis {
  width: 30px;
  height: 7px;
  display: block;
  margin-left: -5px;
}

.ui-loader.small .lds-ellipsis div {
  top: 0px !important;
  width: 7px;
  height: 7px;
}

.ui-loader.small .lds-ellipsis div:nth-child(1) {
  left: 4px;
  animation: lds-ellipsis1 0.6s infinite;
}

.ui-loader.small .lds-ellipsis div:nth-child(2) {
  left: 4px;
  animation: lds-ellipsis2-small 0.6s infinite;
}

.ui-loader.small .lds-ellipsis div:nth-child(3) {
  left: 16px;
  animation: lds-ellipsis2-small 0.6s infinite;
}

.ui-loader.small .lds-ellipsis div:nth-child(4) {
  left: 28px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis2-small {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(12px, 0);
  }
}
</style>
