export default {
  set(x = 0, y = 0) {
    this.position.x = x;
    this.position.y = y;
  },
  get() {
    return this.position;
  },
  position: {
    x: 0,
    y: 0,
  },
};
