/* eslint-disable prefer-template */
export default function flattenObject(obj, prefix = '') {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    const pre = prefix.length ? `${prefix}.` : '';
    const flatKey = `${pre}${key}`;

    if (typeof value === 'object' && value !== null) {
      Object.assign(acc, flattenObject(value, flatKey));
    } else {
      acc[flatKey] = value;
    }

    return acc;
  }, {});
}
