<template>
  <div>
    <app-preloader />

    <ion-app>
      <router-view v-slot="{ Component }">
        <keep-alive>
          <component :is="Component" />
        </keep-alive>
      </router-view>
    </ion-app>
  </div>
</template>

<script>
// eslint-disable import/no-unresolved
// eslint-disable max-len
import { IonApp } from '@ionic/vue';

// eslint-disable-next-line import/extensions
import useUserStore from '@/stores/UserStore';

// eslint-disable-next-line import/extensions
import useOrganizationsStore from '@/stores/OrganizationsStore';

// eslint-disable-next-line import/extensions
import auth from '@/env/auth';

import AppPreloader from '@/components/layout/AppPreloader.vue';

export default {
  name: 'App',
  components: {
    IonApp,
    // IonRouterOutlet,
    AppPreloader,
  },
  setup() {
    const userStore = useUserStore();
    const organizationsStore = useOrganizationsStore();

    return {
      userStore,
      organizationsStore,
    };
  },
  computed: {
    selectedOrganization() {
      return this.organizationsStore.selectedOrganization;
    },
  },
  async created() {
    // auth
    auth.init(async (result) => {
      if (result.status === 'logged-in') {
        // request user info from server
        this.userStore.getUser();

        // request organizations from server
        await this.organizationsStore.getOrganizations();

        // get permissions
        if (this.organizationsStore?.selectedOrganization) {
          this.userStore.getPermissions({
            organizationId: this.organizationsStore?.selectedOrganization?.id,
          });
        }

        // get platform permissions
        this.userStore.getPlatformPermissions({
        });
      } else {
        this.userStore.clearUser();

        // redirect to auth
        if (this.$route.name !== 'Auth' && this.$route.name !== 'LegalNotice' && this.$route.name !== 'Privacy') {
          this.$router.replace({ name: 'Auth' });
        }
      }
    });
  },
};
</script>

<style>
/* antonio-regular - latin */
@font-face {
  font-family: 'Antonio';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/antonio/antonio-v11-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./assets/fonts/antonio/antonio-v11-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/fonts/antonio/antonio-v11-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/antonio/antonio-v11-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('./assets/fonts/antonio/antonio-v11-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./assets/fonts/antonio/antonio-v11-latin-regular.svg#Antonio') format('svg'); /* Legacy iOS */
}
/* antonio-600 - latin */
@font-face {
  font-family: 'Antonio';
  font-style: normal;
  font-weight: 600;
  src: url('./assets/fonts/antonio/antonio-v11-latin-600.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./assets/fonts/antonio/antonio-v11-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/fonts/antonio/antonio-v11-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/antonio/antonio-v11-latin-600.woff') format('woff'), /* Modern Browsers */
       url('./assets/fonts/antonio/antonio-v11-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./assets/fonts/antonio/antonio-v11-latin-600.svg#Antonio') format('svg'); /* Legacy iOS */
}

/* noto-sans-jp-regular - latin */
@font-face {
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/noto-sans-jp/noto-sans-jp-v42-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./assets/fonts/noto-sans-jp/noto-sans-jp-v42-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/fonts/noto-sans-jp/noto-sans-jp-v42-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/noto-sans-jp/noto-sans-jp-v42-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('./assets/fonts/noto-sans-jp/noto-sans-jp-v42-latin-regular.svg#NotoSansJP') format('svg'); /* Legacy iOS */
}

:root {
  color-scheme: light;
  --default-content-padding: 1.2rem;
  --default-content-max-width: 780px;
  --default-card-padding: 1.8em;
  --default-padding: 1.5em;
  --default-padding-large: 1.8em;
  --default-margin: 1em;
  --default-border-radius: 1.3em;
  --default-logo-width: 4em;
  --header-height: calc(5.2em + var(--safe-area-top));
  --tab-nav-height: calc(4em + var(--safe-area-bottom));

  --font-size-primary: 2.1rem;
  --font-size-secondary: 1.65rem;
  --font-size-tertiary: 1.3rem;
  --font-size-quaternary: 1.1rem;

  --line-height-small: 1.65em;

  --color-primary: #5a5aa5;
  --color-secondary: #dedc00;
  --color-secondary-hover: #e7e68c;
  --color-selection: #f8f79d;
  --color-text: #313031;
  --color-text-light: #ffffff;
  --color-text-secondary: #434243;
  --color-background-header: #ffffff;
  --color-background: #ffffff;
  --color-background-secondary: #f3f3f3;
  --color-background-secondary-active: #e4e1e1;
  --color-background-secondary-inactive: #f3f3f396;
  --color-background-tertiary: #dbdbdb;
  --color-background-quaternary: #cdcde3;

  --color-background-warning: #f0e6c6;

  --color-border: #eeeeee;

  --color-field: #242424;
  --color-field-active: #292929;
  --color-field-border: #333333;
  --color-field-border-active: #3f3f3f;

  --color-glass-primary: #696969a9;

  --color-neutral: #b2b2b2;
  --color-neutral-background: #e4e4e4;
  --color-neutral-text: #4e4b4e;

  --color-error-background: #ecbbc2;
  --color-error-text: #5a2b32;

  --color-error: #FF3D5A;
  --color-warning: #E2AF0D;
  --color-success: #59CA66;
  --color-success-text: #315535;
  --color-success-background: #c1e6c4;
  --color-info: #197CFF;

  --color-type-order: #df996b;
  --color-type-return: #e284a2;
  --color-type-credit-note: #87aee2;
  --color-type-warranty: #96e4c4;

  --color-priority-order-background: #d3edf5;
  --color-priority-order-background-hover: #80d7f4;
  --color-priority-order-background-active: #00A8E1;

  --color-background-transparent: rgba(5, 5, 5, 0);

  --color-card-border: rgba(117, 117, 117, 0.9);
  --color-card-outline-secondary: #1b1b1b;

  --default-gradient-floor-fade: linear-gradient(to top, #050505c0, transparent);
  --default-text-shadow: 0em 0em 0.4em #05050594;

  --ion-color-primary: var(--color-primary);
  --ion-default-font: 'Noto Sans JP' !important;
  --ion-font-family: 'Noto Sans JP' !important;

  --font-size-small: 0.9em;
  --font-size-x-small: 0.75em;

  --font-weight-base: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 600;

  --default-font-size: 1em;
  --default-font-family: 'Noto Sans JP';

  --margin-buttons: 0.5em;

  --space-modal-top-l: 5em;
  --space-xs: 0.25em;
  --space-s: 0.7em;
  --space-base: 1.2em;
  --space-l: 2.2em;
  --space-xl: 5.5em;

  --corner-radius-s: 0.8em;
  --corner-radius-base: 1.2em;

  --breakpoint-mobile: 600px;
  --max-content-width: 850px;
  --safe-area-top: var(--ion-safe-area-top);
  --safe-area-bottom: var(--ion-safe-area-bottom);
  --safe-area-left: var(--ion-safe-area-left);
  --safe-area-right: var(--ion-safe-area-right);
}

* {
  box-sizing: border-box;

  /* DISABLE UNWANTED MENUS ON IOS */
  -webkit-touch-callout: none;
  -webkit-user-callout: none;
  /* user-select: none; */
  /* -webkit-user-select: none; */
  -webkit-user-drag: none;
  -webkit-user-modify: none;
  -webkit-highlight: none;
}

html, body {
  background-color: var(--color-background);
  color: var(--color-text);
  line-height: 1.69em;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;
  text-rendering: geometricPrecision;
  overscroll-behavior: none;
  word-break: break-word;
  hyphens: manual;
  -webkit-hyphens: manual;
  font-size: 16px;
  /* user-select: none;
  -webkit-user-select: none; */
}

body, h1, h2, h3, h4, h5, h6, p, span, i, b, a, strong, button, input, textarea {
  font-family: 'Noto Sans JP' !important;
  color: var(--color-text);
  margin: 0px;
}

html, html.ios, html.md {
  --ion-default-font: 'Noto Sans JP' !important;
  --ion-font-family: 'Noto Sans JP' !important;
}

h1, h2, h3, h4, h5, h6, .f-primary, .f-secondary, .f-tertiary, .f-quaternary {
  font-family: 'Antonio' !important;
}

p {
  font-size: 1em;
  font-weight: 400;
  line-height: 1.65em;
}

mark {
  background: transparent;
  color: var(--color-primary);
}

.f-eyebrow-primary {
  font-size: var(--font-size-primary);
  font-weight: 800;
  font-size: calc(var(--font-size-primary) - 0.8em);
  color: var(--color-primary);
}

h1, .f-primary {
  font-size: var(--font-size-primary);
  font-weight: 800;
  padding-bottom: 0.8rem; /* without padding, letters like "g" would be displayed cut off */
  line-height: 3.3rem;
  color: var(--color-text);
}

h2, .f-secondary {
  font-size: var(--font-size-secondary);
  font-weight: 800;
  padding-bottom: 0.9rem; /* without padding, letters like "g" would be displayed cut off */
  line-height: 2.5rem;
  color: var(--color-text);
}

h3, .f-tertiary {
  font-size: var(--font-size-tertiary);
  font-weight: 800;
  padding-bottom: 0.5rem; /* without padding, letters like "g" would be displayed cut off */
  line-height: 2rem;
  color: var(--color-text);
}

h4, .f-quaternary {
  font-size: var(--font-size-quaternary);
  font-weight: 800;
  padding-bottom: 0.5rem; /* without padding, letters like "g" would be displayed cut off */
  line-height: 1.8rem;
  color: var(--color-text);
}

.f-small {
  font-size: var(--font-size-small);
}

a {
  text-decoration: none;
}

/* utility classes */
.loading-spacing {
  padding-top: 4em;
  padding-bottom: 4em;
}

.loading-spacing-s {
  padding-top: 1.5em;
  padding-bottom: 1.5em;
}

.loading-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.align-center {
  text-align: center;
}

.align-right {
  text-align: right;
}

.hide-on-mobile {
  display: block;
}

.hide-on-desktop {
  display: none;
}

@media (max-width: 1050px) {
  .hide-on-mobile {
    display: none !important;
  }

  .hide-on-desktop {
    display: block !important;
  }
}

.title-space-bottom {
  margin-bottom: 1em;
}

.small-space-b {
  margin-bottom: 0.5em;
}

.default-space-b {
  margin-bottom: 1em;
}

.default-space-t {
  margin-top: 1em;
}

.default-space-r {
  margin-right: 1em;
}

.large-space-t {
  margin-top: 1.5em;
}

.large-space-b {
  margin-bottom: 1.5em;
}

.outgoing-link {
  cursor: pointer;
  margin-right: 0.5em;
  color: var(--color-primary);
}

.field-spacing-b {
  margin-bottom: 1.5em;
}

.logo-spacing-b {
  margin-bottom: 1em;
}

.card-spacing-b {
  margin-bottom: 0.6em;
}

.card-spacing-b:last-child {
  margin-bottom: 0em;
}

.feature-description {
  color: var(--color-text-secondary);
  font-size: var(--font-size-small);
}

/* DIALOG */
ion-modal.ui-dialog {
  --width: 400px;
  --height: auto;
  --max-height: 70vh;
  --border-radius: 1em;
}

@media (max-width: 460px) {
  ion-modal.ui-dialog {
    --width: calc(100% - (var(--default-content-padding) * 2));
  }
}

/* POPOVER */
ion-popover {
  --background: var(--color-background);
  --color: var(--color-text);
  --backdrop-opacity: 0.8;
  --box-shadow: 5px 5px 10px 2px rgba(95,93,93,0.15);
  --color: white;
  --width: 200px;
  --offset-x: -0.65em;
  --offset-y: 1em;
  --max-height: 200px;

  --default-content-padding: 1em;
}

ion-popover ion-content {
  --background: var(--color-background);
}

ion-popover::part(backdrop) {
  background-color: transparent;
}

ion-popover::part(.popover-content) {
  border-top-right-radius: 0px !important;
}

/* TOOLTIP */
.v-popper--theme-tooltip {
 background: var(--color-background);
 border-radius: 0.4em;
 border: 1px solid var(--color-background-tertiary);
 padding: 0.2em;
 padding-left: 1.2em;
 padding-right: 1.2em;
 transition: 0s;
 font-size: var(--font-size-small);
 line-height: var(--line-height-small);
 max-width: 350px;
}

/* IONIC */
/* .ion-page {
  overflow: scroll !important;
} */
/*
.v-popper--theme-tooltip.v-popper__popper--show-to .v-popper__wrapper {
  opacity: 1;
  transition: 0.15s;
}

.v-popper--theme-tooltip.v-popper__popper--show-from .v-popper__wrapper {
  opacity: 0;
} */
</style>
