/* eslint-disable import/no-useless-path-segments */
import request from '../utility/request';
import generateRequestURL from './generate-request-url';
// eslint-disable-next-line import/extensions
import auth from '@/env/auth';

let apiURL = 'https://platform-core.services.api.dealdate.net';

if (process.env.NODE_ENV === 'development') {
  apiURL = 'http://localhost:3000';
}

// eslint-disable-next-line arrow-body-style
export default (path, options = {}) => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    try {
      // get id token
      const idToken = await auth.getIdToken();

      // generate request url
      const requestURL = generateRequestURL(`${apiURL}${path}`, options);

      // generate headers
      const headers = {
        'Content-Type': 'application/json',
      };

      headers.Authorization = `Bearer ${idToken}`;

      // make request
      let response = null;

      // GET Request
      if (options.method === 'GET') {
        response = await request.get(requestURL, {
          headers,
        });
      }

      // POST Request
      if (options.method === 'POST') {
        response = await request.post(requestURL, options.body, {
          headers,
        });
      }

      // PATCH Request
      if (options.method === 'PATCH') {
        response = await request.patch(requestURL, options.body, {
          headers,
        });
      }

      // DELETE Request
      if (options.method === 'DELETE') {
        response = await request.delete(requestURL, {
          ...options,
          headers,
        });
      }

      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
};
