/* eslint-disable import/no-unresolved */
// import { createRouter, createWebHistory } from '@ionic/vue-router';
import { createRouter, createWebHistory } from 'vue-router';

import NavWrapper from '@/views/NavWrapper.vue';

import scrollPosition from '../env/scroll-position';

const routes = [
  {
    path: '/p/',
    component: NavWrapper,
    children: [
      {
        path: '/',
        redirect: '/orders',
      },
      {
        path: '/orders',
        name: 'Orders',
        component: () => import('@/views/Orders/index.vue'),
      },
      {
        path: '/returns',
        name: 'Returns',
        component: () => import('@/views/Returns/index.vue'),
      },
      {
        path: '/orders/:orderId',
        name: 'OrderDetail',
        component: () => import('@/views/OrderDetail/index.vue'),
      },
      {
        path: '/items',
        name: 'Items',
        component: () => import('@/views/Items/index.vue'),
      },
      {
        path: '/more',
        name: 'More',
        component: () => import('@/views/More/index.vue'),
      },
      {
        path: '/documents',
        name: 'Documents',
        component: () => import('@/views/Documents/index.vue'),
      },
      {
        path: '/settings/users',
        name: 'SettingsUsers',
        component: () => import('@/views/SettingsUsers/index.vue'),
      },
      {
        path: '/settings/users/:userId',
        name: 'SettingsUserDetail',
        component: () => import('@/views/SettingsUserDetail/index.vue'),
      },
      {
        path: '/settings/printers',
        name: 'SettingsPrinters',
        component: () => import('@/views/SettingsPrinters/index.vue'),
      },
      {
        path: '/platform/plentymarkets',
        name: 'Plentymarkets',
        component: () => import('@/views/Plentymarkets/index.vue'),
      },
      {
        path: '/platform/amazon',
        name: 'Amazon',
        component: () => import('@/views/Amazon/index.vue'),
      },
      {
        path: '/platform/export',
        name: 'PlatformExport',
        component: () => import('@/views/PlatformExport/index.vue'),
      },
      {
        path: '/auth',
        name: 'Auth',
        component: () => import('@/views/Auth/index.vue'),
        props: {
          canvasMode: true, // hide everything except the content
        },
      },
      {
        path: '/legal-notice',
        name: 'LegalNotice',
        component: () => import('@/views/static/LegalNotice/index.vue'),
        props: {
          canvasMode: true, // hide everything except the content
        },
      },
      {
        path: '/privacy',
        name: 'PrivacyPolicy',
        component: () => import('@/views/static/PrivacyPolicy/index.vue'),
        props: {
          canvasMode: true, // hide everything except the content
        },
      },
    ],
  },
];

const scrollPositions = {};
let contentEl = null;

function getContentElement() {
  contentEl = document.querySelector('ion-content');

  if (contentEl === null) {
    setTimeout(() => {
      getContentElement();
    }, 100);
  }
}

getContentElement();

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  linkActiveClass: 'active',
  scrollBehavior(to, from, savedPosition) {
    if (to.fullPath === from.fullPath) {
      return;
    }

    const isNavigationForward = (savedPosition === null);
    contentEl = document.querySelector('ion-content');

    if (isNavigationForward) {
      // save scroll position
      scrollPositions[from.fullPath] = scrollPosition.get();

      contentEl.scrollToTop();
    } else {
      const newSavedPosition = scrollPositions[to.fullPath];

      if (newSavedPosition) {
        setTimeout(() => {
          console.log('scroll to');
          contentEl.scrollToPoint(newSavedPosition.x, newSavedPosition.y);
        }, 700);
      }
      delete scrollPositions[to.fullPath];
    }
  },
  routes,
});

export default router;
