<template>
  <div class="ui-list-item" :class="{ 'disabled': disabled }" data-testid="list-item" :style="`background-color: ${backgroundColor};`">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'UIListItem',
  components: {
  },
  props: {
    backgroundColor: {
      type: String,
      default: 'var(--color-background-secondary-inactive)',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
    };
  },
  computed: {
  },
  methods: {
  },
};
</script>

<style scoped>
.ui-list-item {
  background: var(--color-background-secondary-inactive);
  color: var(--color-text);
  border-radius: 0.8em;
  padding: 0.5em;
  padding-right: 0.8em;
  padding-left: 0.8em;
  transition: 0.1s;
}

.ui-list-item.disabled {
  opacity: 0.5;
  pointer-events: none;
  background: var(--color-neutral) !important;
  color: var(--color-neutral-text) !important;
}
</style>
