// eslint-disable-next-line import/extensions
import flattenObject from '@/utility/flatten-object';

/**
 * Checks if a user has permissions to do a specific action.
 * @param {Object} opts
 * @param {string[]} opts.permissions Permissions the user has to have
 * @param {Object} config
 * @param {Object} config.permissions Permissions object
 * @param {Object} config.user User object
 */
// eslint-disable-next-line import/prefer-default-export
export function checkPermissions(opts, config) {
  if (config?.user?.isComAdmin || config?.permissions?.isAdmin) {
    return {
      permitted: true,
    };
  }

  if (!config?.permissions) {
    return {
      permitted: false,
    };
  }

  // Check Permissions
  const flattenedPermissions = flattenObject(config?.permissions);

  const permitted = opts?.permissions?.every((permission) => {
    const isPermitted = flattenedPermissions[permission] === true ?? false;

    return isPermitted;
  });

  return {
    permitted,
  };
}
