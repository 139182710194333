<template>
  <div class="organization-switch" :class="{ 'small': small }" @click="openOrganizationPopover">
    <span class="label" data-testid="label" v-if="!loading">{{ computedLabel }}</span>
    <div v-if="loading" class="loader">
      <ui-loader small />
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line import/extensions
import useOrganizationsStore from '@/stores/OrganizationsStore';

// eslint-disable-next-line import/extensions
import useUserStore from '@/stores/UserStore';

import PopoverSwitchOrganization from '@/components/organization-switch/popovers/PopoverSwitchOrganization.vue';

import UiLoader from '@/components/ui/UILoader.vue';

// eslint-disable-next-line import/extensions
import popover from '@/env/popover';

export default {
  name: 'OrganizationSwitch',
  components: {
    // eslint-disable-next-line vue/no-unused-components
    PopoverSwitchOrganization,
    UiLoader,
  },
  props: {
    small: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const organizationsStore = useOrganizationsStore();
    const userStore = useUserStore();

    return {
      organizationsStore,
      userStore,
    };
  },
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    organizations() {
      return this.organizationsStore.organizations;
    },
    selectedOrganization() {
      return this.organizationsStore.selectedOrganization;
    },
    computedLabel() {
      if (!this.selectedOrganization) {
        return this.$t('common.all');
      }

      return this.selectedOrganization.name;
    },
  },
  watch: {
    selectedOrganization() {
      if (!this.selectedOrganization) {
        // all selected
        return;
      }

      this.userStore.getPermissions({
        organizationId: this.selectedOrganization?.id,
      });
    },
  },
  async created() {
    this.loading = true;
    // fetch organizations
    await this.organizationsStore.getOrganizations();
    this.loading = false;
  },
  methods: {
    openOrganizationPopover(event) {
      console.log('POPOVER EVENT', event);
      popover.show(PopoverSwitchOrganization, event, {
        side: 'bottom',
        alignment: 'end',
      }, {
        organizations: this.organizations,
      }, (result) => {
        console.log('POPOVER RESOLVED WITH', result);

        this.organizationsStore.selectOrganization(result.organizationId);

        // if (result.type === 'assigned-to-me') {
        //   this.assignToMe = true;
        // }

        // if (result.type === 'unassign-from-me') {
        //   console.log('unassign');
        //   this.assignToMe = false;
        //   this.assignedUserId = null;
        // }
      });
    },
  },
};
</script>

<style scoped>
.organization-switch {
  padding: 0.5em;
  padding-top: 0.62em;
  padding-bottom: 0.62em;
  border-radius: 0.62em;
  transition: 0.1s;
  cursor: pointer;
  min-height: 3em;
  background: var(--color-background-secondary);
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.label {
  color: var(--color-text);
}

.small .label {
  font-size: var(--font-size-small);
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
</style>
