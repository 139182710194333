<template>
  <div class="logo-dealdate">
    <img src="../../assets/img/logos/dealdate-logo.svg" class="logo">
  </div>
</template>

<script>
export default {
  name: 'LogoCommutuus',
  components: {
  },
  props: {
  },
  data() {
    return {
    };
  },
  methods: {
  },
};
</script>

<style scoped>
.logo-dealdate .logo {
  width: 140px;
}
</style>
