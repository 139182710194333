export default function deepMerge(obj1, obj2) {
  const mergedObj = {};

  // eslint-disable-next-line no-restricted-syntax, prefer-const
  for (let key in obj1) {
    // Check if key exists in both objects
    if (key in obj2 && typeof obj1[key] === 'object' && typeof obj2[key] === 'object') {
      // If both values are objects, recursively merge them
      mergedObj[key] = deepMerge(obj1[key], obj2[key]);
    } else {
      // Take the value from the second object
      mergedObj[key] = obj2[key] !== undefined ? obj2[key] : obj1[key];
    }
  }

  // Add remaining keys from obj2
  // eslint-disable-next-line no-restricted-syntax
  for (const key in obj2) {
    if (!(key in obj1)) {
      mergedObj[key] = obj2[key];
    }
  }

  return mergedObj;
}
