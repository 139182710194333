<template>
  <div
    class="ui-content"
    :class="{
      'limit-width': limitWidth,
      'small-width': smallWidth,
      'safe-area-top': safeAreaTop,
      'safe-area-bottom': safeAreaBottom,
      'no-padding': noPadding,
      'padding-lr': paddingLr,
      'intro': intro,
    }"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'UIContent',
  components: {
  },
  props: {
    limitWidth: {
      type: Boolean,
      default: false,
    },
    smallWidth: {
      type: Boolean,
      default: false,
    },
    safeAreaTop: {
      type: Boolean,
      default: false,
    },
    safeAreaBottom: {
      type: Boolean,
      default: false,
    },
    noPadding: {
      type: Boolean,
      default: false,
    },
    paddingLr: {
      type: Boolean,
      default: false,
    },
    intro: { // intro: when this element is the first on the page, the padding-top is larger
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
    };
  },
  methods: {
  },
};
</script>

<style scoped>
.ui-content {
  width: 100%;
  padding: var(--default-content-padding);
}

.ui-content.limit-width {
  max-width: var(--default-content-max-width);
  margin-left: auto;
  margin-right: auto;
}

.ui-content.limit-width.small-width {
  max-width: 600px !important;
}

.ui-content.safe-area-top {
  margin-top: calc(var(--safe-area-top) + 2.8em);
}

.ui-content.safe-area-bottom {
  margin-bottom: calc(var(--safe-area-bottom) + 4em);
}

.ui-content.no-padding {
  padding: 0em;
}

.ui-content.padding-lr {
  padding: 0em;
  padding-left: var(--default-content-padding);
  padding-right: var(--default-content-padding);
}

.ui-content.intro {
  padding-top: 4em;
}

@media (max-width: 1050px) {
  .ui-content.intro {
    padding-top: var(--default-content-padding);
  }
}
</style>
