import { defineStore } from 'pinia';

// eslint-disable-next-line import/extensions
import userApi from '@/api/user';

// eslint-disable-next-line import/extensions
import deepMerge from '@/utility/deep-merge';

// eslint-disable-next-line import/extensions
import { checkPermissions } from '@/utility/permissions/index';

const defaultOrganizationPermissions = {
  app: {
    allowOrdersView: true,
    allowReturnsView: false,
    allowItemsView: true,
    allowMoreView: true,
  },
};

const useUserStore = defineStore('UserStore', {
  state() {
    return {
      user: {
        id: '',
        uid: '',
        organizationId: '',
        profilePicture: '',
        firstName: '',
        lastName: '',
        displayName: '',
        email: '',
        isLoggedIn: false,
      },
      permissions: {
        hasDistinctOrganizationPermissions: false,
        organization: defaultOrganizationPermissions,
        platform: null,
      },
    };
  },
  actions: {
    async getUser() {
      try {
        const user = await userApi.getUser();

        this.user = user.data;
        this.user.isLoggedIn = true;
      } catch (error) {
        console.log('Failed to fetch user', error);
      }
    },
    async getPermissions(config) {
      try {
        const organizationPermissions = await userApi.getOrganizationPermissions(config);

        if (organizationPermissions?.data) {
          this.permissions.hasDistinctOrganizationPermissions = true;
        } else {
          this.permissions.hasDistinctOrganizationPermissions = false;
        }

        this.permissions.organization = deepMerge(defaultOrganizationPermissions, {
          organizationId: organizationPermissions.data?.organizationId,
          isAdmin: organizationPermissions.data?.isAdmin,
          ...organizationPermissions.data?.permissions,
        });
      } catch (error) {
        console.log('Failed to fetch permissions', error);
        this.error = error.type;
        this.permissions.hasDistinctOrganizationPermissions = false;
      }
    },
    async getPlatformPermissions() {
      try {
        const platformPermissions = await userApi.getPlatformPermissions();

        if (!platformPermissions?.data) {
          this.permissions.platform = null;
        } else {
          this.permissions.platform = platformPermissions.data?.permissions;
        }
      } catch (error) {
        console.log('Failed to fetch platform permissions', error);
        this.error = error.type;
      }
    },
    /**
     * Checks if the user either has all requested organization permissions or all requested platform permissions
     * @param {string[]} requestedPermissions Requested platform or organization permissions. Organization permissions must be prefixed with 'org.' and platform permissions with 'platform.'
     * @returns {Boolean} Whether the user has the required permissions
     */
    isPermitted(requestedPermissions) {
      let permitted = false;

      const requestedOrganizationPermissions = requestedPermissions
        ?.filter((permission) => permission?.split('.')?.[0] === 'org')
        ?.map((permission) => permission?.replace('org.', ''));

      const requestedPlatformPermissions = requestedPermissions
        ?.filter((permission) => permission?.split('.')?.[0] === 'platform')
        ?.map((permission) => permission?.replace('platform.', ''));

      let organizationPermissionResult = null;
      let platformPermissionResult = null;

      if (requestedOrganizationPermissions?.length) {
        organizationPermissionResult = checkPermissions({
          permissions: requestedOrganizationPermissions,
        }, {
          permissions: this.permissions?.organization,
          user: this.user,
        });
      }

      if (requestedPlatformPermissions?.length) {
        platformPermissionResult = checkPermissions({
          permissions: requestedPlatformPermissions,
        }, {
          permissions: this.permissions?.platform,
          user: this.user,
        });
      }

      permitted = platformPermissionResult?.permitted || organizationPermissionResult?.permitted;

      return permitted;
    },
    async clearUser() {
      this.user = {
        id: '',
        uid: '',
        organizationId: '',
        profilePicture: '',
        firstName: '',
        lastName: '',
        displayName: '',
        email: '',
        isLoggedIn: false,
      };
    },
    // async isPermitted(permissions) {

    // },
  },
  getters: {
  },
});

export default useUserStore;
