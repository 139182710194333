<template>
  <ui-list-item class="ui-list-item-clickable" :class="{ 'disabled': disabled, 'action': action }" :style="`--list-item-background-color-hover: ${backgroundColorHover}; --list-item-color: ${color}; background-color: ${backgroundColor};`" :disabled="disabled" data-testid="list-item-clickable">
    <div class="icon" data-testid="icon" v-if="icon || action">
      <slot />
    </div>
    <div>
      <span class="label" data-testid="label">
        {{ label }}
      </span>
      <span class="sublabel" data-testid="sublabel">
        <br />
        {{ sublabel }}
      </span>
    </div>
  </ui-list-item>
</template>

<script>
import UiListItem from '@/components/ui/UIListItem.vue';

export default {
  name: 'UIListItem',
  components: {
    UiListItem,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    sublabel: {
      type: String,
      default: '',
    },
    icon: {
      type: Boolean,
      default: false,
    },
    backgroundColor: {
      type: String,
      default: 'var(--color-background-secondary-inactive)',
    },
    backgroundColorHover: {
      type: String,
      default: 'var(--color-background-secondary)',
    },
    color: {
      type: String,
      default: 'var(--color-text)',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    action: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
    };
  },
  computed: {
  },
  methods: {
  },
};
</script>

<style scoped>
.ui-list-item-clickable {
  cursor: pointer;
  transition: 0.2s;
  display: flex;
  flex-direction: row;
}

.ui-list-item-clickable.action {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
}

.ui-list-item-clickable:hover {
  background: var(--list-item-background-color-hover);
}

.label {
  font-weight: 600;
  color: var(--list-item-color);
}

.disabled .label {
  color: var(--color-neutral-text) !important;
}

.icon {
  font-size: 1em;
  color: var(--color-primary);
  margin-right: 0.8em;
}
</style>
