<template>
  <ion-content
    :fullscreen="fullscreen"
    :scroll-events="scrollEvents"
    @ionScroll="onScroll"
  >
    <div class="app-content">
      <slot />
    </div>
  </ion-content>
</template>

<script>
import {
  IonContent,
} from '@ionic/vue';

import scrollPosition from '../../env/scroll-position';

export default {
  name: 'AppContent',
  components: {
    IonContent,
  },
  props: {
    fullscreen: {
      type: Boolean,
      default: false,
    },
    scrollEvents: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
    };
  },
  methods: {
    onScroll($event) {
      scrollPosition.set($event.detail.scrollLeft, $event.detail.scrollTop);

      this.$emit('onScroll', $event);
    },
  },
};
</script>

<style scoped>
</style>
