import { initializeApp } from 'firebase/app';
import {
  initializeAuth,
  signInWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
  setPersistence,
  browserLocalPersistence,
  onAuthStateChanged,
} from 'firebase/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyApUMZwD17Za7EE_kWikvPyx4xLrxPuOGg',
  authDomain: 'dealdate-auth.firebaseapp.com',
  projectId: 'dealdate-auth',
  storageBucket: 'dealdate-auth.appspot.com',
  messagingSenderId: '612701599812',
  appId: '1:612701599812:web:08533aec3a1449c4486411',
};

// Initialize Firebase
let app = null;
let auth = null;

export default {
  async init(callback) {
    app = initializeApp(firebaseConfig);
    // auth = getAuth(app);
    auth = initializeAuth(app, {
      persistence: browserLocalPersistence,
    });

    onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in
        // get id token
        user.getIdToken().then((idToken) => {
          callback({
            status: 'logged-in',
            user,
            idToken,
          });
        });
      } else {
        // User is signed out
        callback({
          status: 'logged-out',
        });
      }
    });
  },
  async onAuthStateChanged() {
    return new Promise((resolve) => {
      onAuthStateChanged(auth, (user) => {
        if (user) {
          // User is signed in
          resolve(user);
        } else {
          // User is signed out
        }
      });
    });
  },
  async logInWithEmailAndPassword({ email, password }) {
    return new Promise((resolve, reject) => {
      setPersistence(auth, browserLocalPersistence)
        .then(() => {
          // sign in with email and password after the persistence mode was set
          signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
              // Signed in
              const { user } = userCredential;

              resolve(user);
            })
            .catch((error) => {
              reject(error);
            });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async logOut() {
    return new Promise((resolve, reject) => {
      signOut(auth)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async getIdToken() {
    return new Promise((resolve) => {
      // request id token
      onAuthStateChanged(auth, (user) => {
        if (user) {
          // User is signed in
          // get id token
          user.getIdToken().then((idToken) => {
            resolve(idToken);
          });
        } else {
          // User is signed out
          resolve(null);
        }
      });
    });
  },
  async sendPasswordResetEmail({ email }) {
    return new Promise((resolve, reject) => {
      sendPasswordResetEmail(auth, email)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
