<template>
  <ui-router-link :to="to" direction="root">
    <div class="app-tab-nav-item" :class="{ 'active': active }">
      <div>
        <div class="icon">
          <slot />
        </div>
        <span class="label" data-testid="label">{{ label }}</span>
      </div>
    </div>
  </ui-router-link>
</template>

<script>
import UiRouterLink from '@/components/ui/UIRouterLink.vue';

export default {
  name: 'AppTabNavItem',
  components: {
    UiRouterLink,
  },
  props: {
    to: {
      type: Object,
      default: () => {},
    },
    label: {
      type: String,
      default: '',
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
    };
  },
  methods: {
  },
};
</script>

<style scoped>
.app-tab-nav-item {
  padding: 0.5em;
  padding-top: 0.2em;
  padding-bottom: 0.2em;
  border-radius: 0.62em;
  transition: 0.1s;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.app-tab-nav-item.active {
  background: var(--color-background-secondary);
}

.label {
  font-size: var(--font-size-x-small);
  color: var(--color-text);
}

.app-tab-nav-item.active .label {
  color: var(--color-primary);
}

.icon {
  font-size: 1.15em;
  margin-bottom: 0em;
  color: var(--color-text);
  margin-bottom: -0.2em;
}

.app-tab-nav-item.active .icon {
  color: var(--color-primary);
}
</style>
