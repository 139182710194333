<template>
  <!-- <ion-nav-link :router-link="to" :router-direction="direction" class="ui-router-link"> -->
    <router-link :to="to ?? '/'" class="ui-router-link">
      <slot />
    </router-link>
  <!-- </ion-nav-link> -->
</template>

<script>
// import {
//   IonNavLink,
// } from '@ionic/vue';

export default {
  name: 'UIRouterLink',
  components: {
    // IonNavLink,
  },
  props: {
    to: {
      type: Object,
      default: () => {},
    },
    direction: {
      type: String,
      default: 'root',
    },
  },
  data() {
    return {
    };
  },
  computed: {
  },
  methods: {
  },
};
</script>

<style scoped>
.ui-router-link {
  cursor: pointer;
}
</style>
